import React, { useState, useEffect } from "react";
import GlobalStyles from "styles/GlobalStyles";
import MainLandingPage from "MainLandingPage.js";
import Messaging from "Messaging";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "pages/Login";
// import Signup from "pages/Signup";
import SignUp from "pages/SignUp";
import ContactUs from "pages/ContactUs";
import PrivacyPolicy from "pages/PrivacyPolicy";
import SmsInfo from "pages/SmsInfo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "Config";
import Email from "Email";
import Deliverability from "Deliverability";
import Concierge from "Concierge";
import Growth from "Growth";
import AudienceManager from "AudienceManager";
import Integration from "Integration";
import ClientStrategy from "ClientStrategy";
import Complience from "Complience";
import AboutUs from "pages/AboutUs";
import Guide from "Guide";
import CaseStudy from "CaseStudy";
import Voice from "Voice";
import CaseStudyNestle from "CaseStudyNestle";
import GuideBlog from "GuideBlog";
import RevenueCalculator from "Revenue";
import Help from "Help";
import GuideBlogVoice from "GuideBlogVoice";
import GuideBlogEmail from "GuideBlogEmail";
import GuideSenderId from "GuideSenderId";
import SmsWeLove from "SmsWeLove";
import TermsAndConditions from "pages/TermsAndConditions";

export default function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const isUser = localStorage.getItem("user");
    if (isUser && window.location.href != Config.domain + "/cms") {
      setUser(JSON.parse(isUser));
      window.location = Config.domain + "/cms";
    }
  }, []);

  return (
    <>
      {!user ? (
        <>
          <GlobalStyles
            styles={{
              "@keyframes mui-auto-fill": { from: { display: "block" } },
              "@keyframes mui-auto-fill-cancel": { from: { display: "block" } },
            }}
          />
          <ToastContainer
            position="top-center"
            autoClose={10000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            // rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
          />

          <Router>
            <Routes>
              <Route path="/" element={<MainLandingPage />} />
              <Route path="/messaging" element={<Messaging />} />
              <Route path="/email" element={<Email />} />
              <Route path="/sms-deliverability" element={<Deliverability />} />
              <Route path="/concierge" element={<Concierge />} />
              <Route
                path="/audience-segmentation-manager"
                element={<AudienceManager />}
              />
              <Route path="/growth" element={<Growth />} />
              <Route path="/client-strategy" element={<Integration />} />
              <Route path="/sms-we-love" element={<SmsWeLove />} />
              <Route path="/integration" element={<ClientStrategy />} />
              <Route path="/complience" element={<Complience />} />
              <Route path="/guide" element={<Guide />} />
              <Route path="/case-study" element={<CaseStudy />} />
              <Route path="/case-study/nestle" element={<CaseStudyNestle />} />
              <Route path="/guide/bulk-sms" element={<GuideBlog />} />
              <Route path="/guide/voice-sms" element={<GuideBlogVoice />} />

              <Route path="/guide/sender-id" element={<GuideSenderId />} />
              <Route
                path="/guide/email-marketing"
                element={<GuideBlogEmail />}
              />
              <Route path="/voice-sms" element={<Voice />} />
              <Route path="/revenue" element={<RevenueCalculator />} />
              <Route path="/help" element={<Help />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/sign-up" element={<Signup />} /> */}
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
              <Route path="/products/sms-broadcast" element={<SmsInfo />} />
            </Routes>
          </Router>
        </>
      ) : null}
    </>
  );
}
