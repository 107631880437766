const Config = {
  //domain: "https://txtconnect.watchmyproduct.com",
  //apiUrl: "https://txtconnect.watchmyproduct.com/backend/api",
  //nodeApiUrl: "https://txtconnect.watchmyproduct.com/api",
  domain: "https://staging.txtconnect.net",
  apiUrl: "https://staging.txtconnect.net/backend/api",
  nodeApiUrl: "https://staging.txtconnect.net/api",
};

export default Config;
