import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms and Conditions" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1>Introduction</h1>
            <p>
              These Terms and Conditions outline the acceptable use and guidelines for the txtconnect bulk messaging platform in Nigeria. By accessing or using txtconnect, you agree to comply with these terms and any additional policies we establish.
            </p>
            <hr />

            <h2>1. Prohibited Words and Phrases</h2>
            <p>
              To maintain a secure and compliant messaging environment, certain words and phrases are prohibited on the txtconnect platform. A comprehensive list of these "Don't Use" words will be provided and updated as necessary. Users are responsible for ensuring their content adheres to these guidelines to avoid suspension or message rejection.
            </p>

            <h2>2. Sender ID Registration</h2>
            <p>All intended sender IDs must be registered and approved prior to use on txtconnect.</p>
            <ul>
              <li>Sender IDs must contain only alphabetical characters; any inclusion of digits is prohibited.</li>
              <li>Unregistered or unauthorized sender IDs may result in message delivery failure or account suspension.</li>
            </ul>

            <h2>3. Do Not Disturb (DND) Compliance</h2>
            <p>
              Messages sent to phone numbers registered under the Do Not Disturb (DND) list will not be delivered. This compliance is mandated to respect the privacy preferences of recipients, and txtconnect will not be held liable for undelivered messages sent to DND-registered numbers.
            </p>

            <h2>4. Message Scheduling: Restricted Hours</h2>
            <p>
              To prevent unsolicited communication during late hours, messages sent between 8:00 p.m. and 8:00 a.m. will be queued for delivery and automatically dispatched after 8:00 a.m. This scheduling ensures that recipients are not disturbed outside preferred communication hours.
            </p>

            <h2>5. Prohibition of Health-Related Content</h2>
            <p>
              Content related to health, including medical advice, health campaigns, or any content that may be interpreted as health-related, is strictly prohibited on txtconnect. Non-compliance with this policy may lead to message rejection or account suspension.
            </p>
            <hr />

            <h1>Enforcement and Violations</h1>
            <p>
              Failure to comply with these Terms and Conditions may result in message filtering, delivery failure, account suspension, or termination of services. We reserve the right to update these terms as necessary and will notify users of any significant changes.
            </p>

            <h1>Acceptance</h1>
            <p>
              By using txtconnect, you confirm that you have read, understood, and agree to these Terms and Conditions.
            </p>

            <p>
              For further information or questions regarding these policies, please contact our support team.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
